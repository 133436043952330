import PropTypes from "prop-types";
import React, { useState } from "react";
import Icon from "react-icons-kit";
import { spinner } from "react-icons-kit/fa/spinner";
import styled from "styled-components";
import { base, themed } from "./base";
import Box from "./Box";

const ImageWrapper = styled("img")(
  {
    display: "block",
    maxWidth: "100%",
    height: "auto"
  },
  base,
  themed("Image")
);

const Spinner = props => (
  <Icon
    size={48}
    icon={spinner}
    aria-label="loading"
    {...props}
    css={`
      color: ${props => props.theme.colors.primary};
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
  />
);

const Image = ({ src, alt, animate = false, ...props }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Box
        {...props}
        css={`
          display: ${loading ? "flex" : "none"};
          justify-content: center;
          align-items: center;
          height: 100%;
        `}
      >
        <Spinner />
      </Box>

      <ImageWrapper
        {...props}
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
        css={`
          display: ${loading ? "none" : "block"};
          height: 100%;
          animation: fadein 0.5s;

          @keyframes fadein {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      />
    </>
  );
};

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  m: 0
};
